import React, { useState } from 'react';
import { graphql } from 'gatsby';
import AnimateHeight from 'react-animate-height';
import ReactMarkdown from 'react-markdown';
import { Col, Grid, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Blocks, InhouseDay, Layout, Seo } from '../components';
import { InhouseDayProps } from '../utils';
import styles from './inhouseDay.module.css';
import gql from 'graphql-tag';
import { useBackendQuery } from '../hooks/useBackendQuery';

interface QueryResult {
  strapi: {
    inhouseDay: InhouseDayProps,
  },
};

export const query = graphql`
  query InhousedayQuery($id: ID!) {
    strapi {
      inhouseDay(id: $id) {
        id
        slug
        title
        startDate
        endDate
        time
        location
        description
        company {
          name
          logo {
            url
            alternativeText
          }
        }
        finalSignupDate
        image {
          url
          alternativeText
        }
        blocks {
          __typename
          ... on STRAPI_ComponentContentRichText {
            id
            text
          }
          ... on STRAPI_ComponentContentPhotoList{
            title
            description
            photos {
              name
              function
              linkedInUrl
              mailTo
              institution
              photo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentWhiteLogoBar {
            logos {
              link
              logo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentDropletSectionList {
            title
            sections {
              title
              subTitle
              paragraph
              image {
                formats
              }
              buttonLabel
              buttonLink
            }
          }
          ... on STRAPI_ComponentContentCallToAction {
            title
            subTitle
            buttonLabel
            buttonLink
            buttonType
            color
          }
          ... on STRAPI_ComponentContentTimeline {
            title
            steps {
              title
              description
            }
          }
          ... on STRAPI_ComponentContentVideoEmbed {
            title
            embedCode
            thumbnail {
              url
            }
          }
          ... on STRAPI_ComponentContentTestimonialList {
            title
            testimonials {
              study
              quote
              name
              image {
                alternativeText
                id
                url
              }
              id
              excerpt
              age
            }
          }
        }
      }
    }
  }
`;

const BACKEND_QUERY = gql`
query {
  inhouseDays {
    id
    strapiId
    name
    startDate
    endDate
    time
    location
    finalSignupDate
    company {
      name
    }
  }
}`

const InhouseDayTemplate = ({ pageContext, data }: { pageContext: { id: string }, data: QueryResult }) => {
  const { data: backendData } = useBackendQuery(BACKEND_QUERY)
  const inhouseDay = (backendData?.inhouseDays ?? []).find((day) => day.strapiId === Number(pageContext.id))
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [active, setActive] = useState(false);
  if (!inhouseDay) return null;

  const page = {
    ...data.strapi.inhouseDay,
    ...inhouseDay,
    company: {
      ...data.strapi.inhouseDay.company,
      name: inhouseDay.name,
    },
    title: inhouseDay.name,
  };

  return (
    <Layout>
      <Seo title={page.title} />
      <section className={`${styles.inhouseDay} container`}>
        <h2>{page.title} | {page.company.name}</h2>
        <Row gutter={[32, 32]}>
          <Col  md={12} lg={8}>
            <InhouseDay
              isDetailPage={true}
              title={page.title}
              startDate={page?.startDate}
              endDate={page?.endDate}
              time={page.time}
              location={page.location}
              finalSignupDate={page.finalSignupDate}
              company={page.company}
              slug={page.slug}
              id={page.id}
            />
          </Col>
          <Col md={12} lg={16}>
            <img
              className={styles.image}
              src={page?.image?.url} />
          </Col>
        </Row>
        <div className={styles.animateWrap}>
          <AnimateHeight
            duration={1000}
            height={(active || screens.md) ? 'auto' : 100}
          >
            <ReactMarkdown source={page?.description} />
          </AnimateHeight>
          {!screens.md && <p
            className={styles.animateReadMore}
            onClick={() => setActive(!active)}>Lees meer <CaretDownOutlined /></p>}
        </div>
      </section>
      <Blocks blocks={page.blocks} />
    </Layout>
  );
};

export default InhouseDayTemplate;
